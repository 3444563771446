.hp-hello-business {
  background-color: #fff;
  padding-block: 1rem;
}
@media (min-width: 1024px) {
  .hp-hello-business {
    padding-block: var(--space-64);
  }
}
.hp-hello-business__banner {
  --main-color: var(--color-blue-1100);
  --second-color: #f5d660;
  background-color: var(--main-color);
  border-radius: var(--radius-main);
  color: #fff;
  padding: var(--space-48) 1rem var(--space-8);
  position: relative;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: calc(100% - 2rem);
}
.hp-hello-business__banner a {
  color: var(--second-color);
}
@media (min-width: 1024px) {
  .hp-hello-business__banner {
    padding-top: 1.25rem;
    flex-direction: row;
    max-width: 1050px;
    padding-left: var(--space-64);
  }
}
.hp-hello-business__badge {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--second-color);
  color: var(--main-color);
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
  padding: 0.75rem 1rem;
  font-size: 19px;
  letter-spacing: 0.4;
  font-weight: 500;
  line-height: normal;
}
.hp-hello-business__title {
  color: inherit;
  margin-block: var(--space-32);
}
.hp-hello-business__title::before {
  content: none;
}
.hp-hello-business__content {
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 1.26;
  margin-bottom: var(--space-20);
}
@media (min-width: 768px) {
  .hp-hello-business__content {
    font-size: 18px;
  }
}
.hp-hello-business__img img {
  height: auto;
}
@media (min-width: 1024px) {
  .hp-hello-business__wrapper {
    width: 70%;
  }
}